import {
    Box,
    Flex,
    Indicator,
    MediaQuery,
    Text,
    useMantineTheme,
} from "@mantine/core";
import { useRouter } from "next/router";

import Image from "next/image";
import { createStyles, keyframes } from "@mantine/core";
import { useContext } from "react";

// animista.net -> for making animations

export const bounce = keyframes({
    "from, 20%, 53%, 80%, to": { transform: "translate3d(0, 0, 0)" },
    "40%, 43%": { transform: "translate3d(0, -30px, 0)" },
    "70%": { transform: "translate3d(0, -15px, 0)" },
    "90%": { transform: "translate3d(0, -4px, 0)" },
});

export const bgmoving = keyframes({
    from: { backgroundPosition: "40px 40px, 20px 20px, 0 0" },
    to: { backgroundPosition: "20px 20px,0 0, 0 0" },
});

export const ping = keyframes({
    "0%": {
        transform: "scale(0.2)",
        opacity: 0.8,
    },
    "80%": {
        transform: "scale(1.2)",
        opacity: 0,
    },
    "100%": {
        transform: "scale(2.2)",
        opacity: 0,
    },
});

export const trackingInContractBckBottom = keyframes({
    "0%": {
        letterSpacing: "1em",
        transform: `translateZ(400px) translateY(300px)`,
        opacity: 0,
    },
    "40%": {
        opacity: 0.6,
    },
    "100%": {
        transform: `translateZ(0) translateY(0)`,
        opacity: 1,
    },
});

const useStyles = createStyles((theme) => ({
    bouncing: {
        animation: `${bounce} 3s ease-in-out infinite`,
    },
    indicator: {
        // animation: `${ping} 3s ease-in-out infinite both`,
    },
    bgmoving: {
        backgroundImage: `radial-gradient(white 2%, transparent 10%),
        radial-gradient(#fafafa 2%, transparent 10%), linear-gradient(45deg, #1971c2 0%, #3b5bdb 100%)`,
        backgroundColor: `#e53935`,
        backgroundSize: `40px 40px, 40px 40px, 100% 100%`,

        backgroundPosition: `0 0, 20px 20px`,
        animation: `${bgmoving} 3s linear infinite`,
    },
    textentrance: {
        animation: `${trackingInContractBckBottom} 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both`,
    },
}));

const VSVlogo = ({ config_type = "mts", shortversion = false }) => {
    const theme = useMantineTheme();
    const dark = theme.colorScheme == "dark";

    const config = {
        mts: {
            site_name: "MTS",
            full_form: "MINING TEST SERIES",
            company_name: "Exam Test Series LLC",
        },
        ets: {
            site_name: "ETS",
            full_form: "EXAM TEST SERIES LLC",
            company_name: "",
        },
    };
    const { site_name, full_form, company_name } = config[config_type];

    const router = useRouter();

    const { classes } = useStyles();

    return (
        <Indicator
            inline
            label="2024"
            classNames={{ indicator: classes.indicator }}
            size={16}
        >
            <Box
                sx={{
                    border: `2px solid black`,
                    borderRadius: 10,
                    overflow: "hidden",
                    cursor: "pointer",
                }}
                onClick={() => {
                    router.push("/");
                }}
            >
                <Flex sx={{ alignItems: "stretch", minHeight: 50 }}>
                    <Box
                        px={20}
                        className={classes.bgmoving}
                        sx={(theme) => ({
                            backgroundColor: "black",
                            // backgroundImage: theme.fn.gradient({
                            //     from: "blue",
                            //     to: "indigo",
                            //     deg: 45,
                            // }),
                        })}
                    >
                        <Flex
                            align={"center"}
                            justify={"center"}
                            w="100%"
                            h="100%"
                        >
                            <Text c={"white"} fw={"bolder"} fz={"lg"}>
                                {site_name}
                            </Text>
                        </Flex>
                    </Box>
                    <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                        <Flex
                            direction={"column"}
                            gap={2}
                            px={5}
                            sx={(theme) => ({
                                backgroundColor: "white",
                                height: 50,
                            })}
                            justify="center"
                        >
                            <Box>
                                <Text
                                    component="p"
                                    sx={{ lineHeight: 1 }}
                                    m={0}
                                    fw={"bold"}
                                    variant="gradient"
                                    gradient={{
                                        from: "indigo",
                                        to: "cyan",
                                        deg: 45,
                                    }}
                                >
                                    {full_form}
                                </Text>
                            </Box>
                            {!!company_name && (
                                <Box>
                                    <Text
                                        align="center"
                                        sx={{ lineHeight: 1 }}
                                        fz={"xs"}
                                        c={"black"}
                                        fw={"bold"}
                                        variant="gradient"
                                        gradient={{
                                            from: "indigo",
                                            to: "cyan",
                                            deg: 45,
                                        }}
                                    >
                                        - {company_name}
                                    </Text>
                                </Box>
                            )}
                        </Flex>
                    </MediaQuery>
                </Flex>
            </Box>
        </Indicator>
    );
};

export default VSVlogo;
