import Head from "next/head";
import {
    MantineProvider,
    ColorSchemeProvider,
    ScrollArea,
} from "@mantine/core";
import "../styles/globals.css";
import { rtlCache } from "../util/rtl-cache";
import { Fragment, useState } from "react";
import { customTheme } from "../../../packages/ui2/customTheme";
import { HeaderResponsive } from "../../../packages/ui2/components/HeaderResponsive";
import { FooterSection } from "../../../packages/ui2/components/FooterSection";
import { SessionProvider } from "next-auth/react";
import { NotificationsProvider } from "@mantine/notifications";
import { useLocalStorage } from "@mantine/hooks";
import {
    IconCoinRupee,
    IconFileReport,
    IconListNumbers,
    IconReportSearch,
} from "@tabler/icons";

export default function App({
    Component,
    pageProps: { session, ...pageProps },
}) {
    // const [colorScheme, setColorScheme] = useState("dark");
    const [colorScheme, setColorScheme] = useLocalStorage({
        key: "mantine-color-scheme",
        defaultValue: "dark",
        getInitialValueInEffect: true,
    });

    const toggleColorScheme = (value) =>
        setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
    const links = [
        {
            label: "Dashboard",
            link: "/dashboard",
            icon: IconListNumbers,
        },
        {
            label: "Syllabus",
            link: "/syllabus",
            icon: IconReportSearch,
        },
        // {
        //     label: "Earn",
        //     link: "/earn",
        //     icon: IconCoinRupee,
        // },
    ];
    return (
        <Fragment>
            <Head>
                <title>ExamTestSeries</title>
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width"
                />
            </Head>
            <SessionProvider session={session}>
                <ColorSchemeProvider
                    colorScheme={colorScheme}
                    toggleColorScheme={toggleColorScheme}
                >
                    <MantineProvider
                        withGlobalStyles
                        withNormalizeCSS
                        emotionCache={rtlCache}
                        theme={customTheme(colorScheme)}
                    >
                        <NotificationsProvider>
                            <HeaderResponsive links={links}>
                                <Component {...pageProps} />
                                <FooterSection />
                            </HeaderResponsive>
                        </NotificationsProvider>
                    </MantineProvider>
                </ColorSchemeProvider>
            </SessionProvider>
        </Fragment>
    );
}
