import {
    useMantineColorScheme,
    ActionIcon,
    Group,
    createStyles,
    Button,
    Switch,
    useMantineTheme,
    Box,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconSun, IconMoonStars, IconCheck } from "@tabler/icons";

const useStyles = createStyles((theme) => ({}));

export function ActionToggle({}) {
    const { colorScheme, toggleColorScheme } = useMantineColorScheme();
    const { classes, cx } = useStyles();
    const theme = useMantineTheme();

    const changeTheme = () => {
        showNotification({
            title: `Switched to ${colorScheme} theme`,
            message: "",
            autoClose: true,
            disallowClose: false,
            color: "teal",
            icon: <IconCheck size={16} />,
        });
        toggleColorScheme();
    };

    return (
        <Box>
            <ActionIcon
                about="change theme"
                aria-label="change theme"
                onClick={changeTheme}
                size="lg"
                sx={(theme) => ({
                    backgroundColor:
                        theme.colorScheme === "dark"
                            ? theme.colors.dark[6]
                            : theme.colors.gray[0],
                    color:
                        theme.colorScheme === "dark"
                            ? theme.colors.yellow[4]
                            : theme.colors.blue[6],
                    [theme.fn.largerThan("sm")]: { display: "none" },
                })}
            >
                {colorScheme === "dark" ? (
                    <IconSun size={18} />
                ) : (
                    <IconMoonStars size={18} />
                )}
            </ActionIcon>
            <Switch
                aria-label="change theme"
                checked={colorScheme === "dark"}
                onChange={changeTheme}
                size="md"
                sx={{
                    display: "flex",
                    [theme.fn.smallerThan("sm")]: { display: "none" },
                }}
                onLabel={<IconSun color={theme.white} size={20} stroke={1.5} />}
                offLabel={
                    <IconMoonStars
                        color={theme.colors.gray[6]}
                        size={20}
                        stroke={1.5}
                    />
                }
            />
        </Box>
    );
}
