import { Box, MediaQuery, Skeleton } from "@mantine/core";

const SuspenseHeaderResponsive = () => {
    return (
        <Box>
            <MediaQuery
                smallerThan={"md"}
                styles={{
                    display: "none",
                }}
            >
                <Skeleton animate={false} height={36} width={134} />
            </MediaQuery>
            <MediaQuery
                largerThan={"md"}
                styles={{
                    display: "none",
                }}
            >
                <Skeleton animate={false} height={55} width={58} />
            </MediaQuery>
        </Box>
    );
};

export default SuspenseHeaderResponsive;
