import { ActionIcon, createStyles, Group, Text } from "@mantine/core";
import {
    IconBrandDiscord,
    IconBrandFacebook,
    IconBrandTelegram,
    IconBrandTwitter,
    IconBrandYoutube,
} from "@tabler/icons";
import Link from "next/link";

const useStyles = createStyles((theme) => ({
    social: {
        [theme.fn.smallerThan("sm")]: {
            marginTop: theme.spacing.xs,
        },
    },
}));

const SocialConnect = () => {
    const { classes } = useStyles();

    return (
        <Group spacing={0} className={classes.social} position="right" noWrap>
            <Text>Join us in: </Text>
            <Link
                href={"https://t.me/miningtesttech"}
                about="social telegram community"
            >
                <ActionIcon
                    size="lg"
                    color={"indigo"}
                    about="social telegram community"
                    aria-label="social telegram community"
                >
                    <IconBrandTelegram size={25} stroke={1.5} />
                </ActionIcon>
            </Link>
            <Link
                href={"https://www.youtube.com/@mts_ets"}
                about="social youtube community"
            >
                <ActionIcon
                    size="lg"
                    color={"red.7"}
                    about="social youtube community"
                    aria-label="social youtube community"
                >
                    <IconBrandYoutube size={25} stroke={1.5} />
                </ActionIcon>
            </Link>
            <Link
                href={"https://twitter.com/ExamTestSeries"}
                about="social twitter community"
            >
                <ActionIcon
                    size="lg"
                    color={"blue"}
                    about="social twitter community"
                    aria-label="social twitter community"
                >
                    <IconBrandTwitter size={25} stroke={1.5} />
                </ActionIcon>
            </Link>
            <Link
                href={"https://www.facebook.com/miningtesttech"}
                about="social facebook community"
            >
                <ActionIcon
                    size="lg"
                    color={"violet"}
                    about="social facebook community"
                    aria-label="social facebook community"
                >
                    <IconBrandFacebook size={25} stroke={1.5} />
                </ActionIcon>
            </Link>
            <Link
                href={"https://discord.gg/dy2erKqSKM"}
                about="social discord community"
            >
                <ActionIcon
                    about="social discord community"
                    aria-label="social-discord-community"
                    size="lg"
                    color={"violet.4"}
                >
                    <IconBrandDiscord size={25} stroke={1.5} />
                </ActionIcon>
            </Link>
        </Group>
    );
};

export default SocialConnect;
