import {
    createStyles,
    Text,
    Container,
    ActionIcon,
    Group,
    Flex,
    Box,
    ThemeIcon,
    useMantineTheme,
} from "@mantine/core";
import {
    IconBrandTwitter,
    IconBrandYoutube,
    IconBrandInstagram,
    IconPhoneCall,
    IconBrandTelegram,
    IconBrandFacebook,
    IconBrandDiscord,
} from "@tabler/icons";
import { MantineLogo } from "@mantine/ds";
import VSVlogo from "./VSVlogo";
import Link from "next/link";
import bgpattern from "../assets/bgpattern.svg";
import SocialConnect from "./SocialConnect";

const useStyles = createStyles((theme) => ({
    footer: {
        // marginTop: 120,
        paddingTop: theme.spacing.xl * 2,
        paddingBottom: theme.spacing.xl * 2,
        backgroundColor:
            theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
        // backgroundImage: `linear-gradient(${theme.colors.dark[7] + "00"}, ${
        //     theme.colors.dark[6]
        // }), url(${bgpattern.src})`,
        // backgroundSize: "auto 1920px ",
        // backgroundRepeat: "repeat-x",

        // maskImage: `url(${bgpattern.src})`,

        borderTop: `1px solid ${
            theme.colorScheme === "dark"
                ? theme.colors.dark[5]
                : theme.colors.gray[2]
        }`,
        position: "relative",
    },

    logo: {
        maxWidth: 300,

        [theme.fn.smallerThan("sm")]: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
    },

    description: {
        marginTop: 5,

        [theme.fn.smallerThan("sm")]: {
            marginTop: theme.spacing.xs,
            textAlign: "center",
        },
    },

    inner: {
        display: "flex",
        justifyContent: "space-between",

        [theme.fn.smallerThan("sm")]: {
            flexDirection: "column",
            alignItems: "center",
        },
    },

    groups: {
        display: "flex",
        flexWrap: "wrap",

        [theme.fn.smallerThan("sm")]: {
            display: "none",
        },
    },

    wrapper: {
        width: 160,
    },

    link: {
        display: "block",
        color:
            theme.colorScheme === "dark"
                ? theme.colors.dark[1]
                : theme.colors.gray[6],
        fontSize: theme.fontSizes.sm,
        paddingTop: 3,
        paddingBottom: 3,

        "&:hover": {
            textDecoration: "underline",
        },
    },

    title: {
        fontSize: theme.fontSizes.lg,
        fontWeight: 700,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        marginBottom: theme.spacing.xs / 2,
        color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },

    afterFooter: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: theme.spacing.xl,
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,
        borderTop: `1px solid ${
            theme.colorScheme === "dark"
                ? theme.colors.dark[4]
                : theme.colors.gray[2]
        }`,

        [theme.fn.smallerThan("sm")]: {
            flexDirection: "column",
        },
    },

    social: {
        [theme.fn.smallerThan("sm")]: {
            marginTop: theme.spacing.xs,
        },
    },
}));

// interface FooterLinksProps {
//   data: {
//     title: string;
//     links: { label: string; link: string }[];
//   }[];
// }
let dummydata = [
    {
        title: "About",
        links: [
            { label: "About us", link: "/about" },
            { label: "Privacy policy", link: "/privacy-policy" },
            { label: "Terms and Conditions", link: "/tos" },
            { label: "Customer Support", link: "/contact-us" },
            { label: "Refund Policy", link: "/refund-policy" },
        ],
    },
    {
        title: "Community",
        links: [
            { label: "Join Telegram", link: "https://t.me/miningtesttech" },
            { label: "Join Discord", link: "https://discord.gg/dy2erKqSKM" },
            {
                label: "Follow on Facebook",
                link: "https://www.facebook.com/miningtesttech",
            },
            {
                label: "Follow on Twitter",
                link: "https://twitter.com/ExamTestSeries",
            },
        ],
    },
];

export function FooterSection({ data = dummydata }) {
    const { classes } = useStyles();
    const theme = useMantineTheme();

    const groups = data.map((group) => {
        const links = group.links.map((link, index) => (
            <Link
                key={index}
                href={link.link}
                passHref
                style={{ textDecoration: "none" }}
            >
                <Text className={classes.link}>{link.label}</Text>
            </Link>
        ));

        return (
            <Box className={classes.wrapper} key={group.title}>
                <Text className={classes.title}>{group.title}</Text>
                {links}
            </Box>
        );
    });

    return (
        <footer className={classes.footer}>
            <Container className={classes.inner}>
                <Flex direction={"column"}>
                    <Flex
                        direction={"column"}
                        align="start"
                        className={classes.logo}
                    >
                        <VSVlogo />
                        <Text
                            size="xs"
                            color="dimmed"
                            className={classes.description}
                        >
                            India&apos;s No. 1 test series for GATE Mining exam
                        </Text>
                    </Flex>
                    <Box>
                        {/* <Flex gap={10}>
                            <IconPhoneCall color={theme.colors.green[8]} />
                            <Text>+91-9523629331</Text>
                        </Flex> */}
                    </Box>
                </Flex>
                <Box className={classes.groups}>{groups}</Box>
            </Container>
            <Container className={classes.afterFooter}>
                <Text color="dimmed" size="sm">
                    © 2023 Exam Test Series LLC. All rights reserved.
                </Text>

                <SocialConnect />
            </Container>
        </footer>
    );
}
