import { useState } from "react";
import {
    createStyles,
    Header,
    Container,
    Group,
    Burger,
    Paper,
    Transition,
    Image,
    useMantineTheme,
    Box,
    Text,
    Divider,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import logo from "../assets/image404.svg";
import lightlogo from "../assets/mlight.jpg";
import darklogo from "../assets/mdark.jpg";
import { ActionToggle } from "./ActionToggle";
import Link from "next/link";
import { useTheme } from "@emotion/react";
// import { LoginButton } from "./LoginButton";
import { useRouter } from "next/router";
import VSVlogo from "./VSVlogo";
import { Icon360, IconHome, IconSmartHome, IconStar } from "@tabler/icons";
import dynamic from "next/dynamic";
import SuspenseHeaderResponsive from "./SuspenseHeaderResponsive";

const HEADER_HEIGHT = 100;

const DynamicLoginButton = dynamic(() => import("./LoginButton"), {
    loading: () => <SuspenseHeaderResponsive />,
    ssr: false,
});

const useStyles = createStyles((theme) => ({
    root: {
        position: "relative",
        zIndex: 10,
    },

    dropdown: {
        position: "absolute",
        top: HEADER_HEIGHT,
        left: 0,
        right: 0,
        zIndex: 0,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderTopWidth: 0,
        overflow: "hidden",

        [theme.fn.largerThan("sm")]: {
            display: "none",
        },
    },

    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
    },

    links: {
        [theme.fn.smallerThan("sm")]: {
            display: "none",
        },
    },

    burger: {
        [theme.fn.largerThan("sm")]: {
            display: "none",
        },
    },

    link: {
        display: "block",
        lineHeight: 1,
        padding: "8px 12px",
        borderRadius: theme.radius.sm,
        textDecoration: "none",
        color:
            theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors.gray[7],
        fontSize: theme.fontSizes.sm,
        fontWeight: 500,

        "&:hover": {
            backgroundColor:
                theme.colorScheme === "dark"
                    ? theme.colors.dark[6]
                    : theme.colors.gray[0],
        },

        [theme.fn.smallerThan("sm")]: {
            borderRadius: 0,
            padding: theme.spacing.md,
        },
    },

    linkPremium: {
        "&, &:hover": {
            backgroundColor: theme.fn.variant({
                variant: "light",
                color: "yellow",
            }).background,
            color: theme.fn.variant({
                variant: "light",
                color: "yellow",
            }).color,
        },
    },

    linkActive: {
        "&, &:hover": {
            backgroundColor: theme.fn.variant({
                variant: "light",
                color: theme.primaryColor,
            }).background,
            color: theme.fn.variant({
                variant: "light",
                color: theme.primaryColor,
            }).color,
        },
    },
}));

export function HeaderResponsive({
    children,
    links = [],
    showLoginButton = true,
    config_type = "mts",
}) {
    let homelink = { label: "Home", icon: IconSmartHome, link: "/" };
    let premiumlink = {
        label: "Premium",
        icon: IconStar,
        type: "premium",
        link: "/premium",
    };
    links = [premiumlink, ...links];

    const [opened, { toggle, close }] = useDisclosure(false);
    // const [active, setActive] = useState(links[0].link);
    const { classes, cx } = useStyles();
    const theme = useMantineTheme();

    const router = useRouter();
    // console.log(router.pathname);

    let items = links.map((link) => (
        <Link
            key={link.label}
            href={link.link}
            className={cx(
                classes.link,
                {
                    [classes.linkPremium]: link.link === "/premium",
                },
                {
                    [classes.linkActive]: router.pathname === link.link,
                }
            )}
            onClick={toggle}
        >
            <Group spacing={5}>
                {!!link.icon && <link.icon size={20} />}
                <Text>{link.label}</Text>
            </Group>
        </Link>
    ));

    items = [...items];

    return (
        <Box>
            <Box h={HEADER_HEIGHT} className={classes.root}>
                <Container className={classes.header}>
                    <Box>
                        <VSVlogo config_type={config_type} />
                    </Box>
                    <Group>
                        <Group spacing={5} className={classes.links}>
                            {items}
                        </Group>
                        <Group>
                            <ActionToggle />

                            {/* <LoginButton /> */}
                            {showLoginButton && <DynamicLoginButton />}
                        </Group>

                        <Burger
                            about="open navigation"
                            opened={opened}
                            onClick={toggle}
                            className={classes.burger}
                            size="sm"
                        />
                    </Group>

                    <Transition
                        transition="pop-top-right"
                        duration={200}
                        mounted={opened}
                    >
                        {(styles) => (
                            <Paper
                                className={classes.dropdown}
                                withBorder
                                style={{ ...styles }}
                            >
                                {items}
                            </Paper>
                        )}
                    </Transition>
                </Container>
                <Divider />
            </Box>
            {children}
        </Box>
    );
}
