export const customTheme = (colorScheme) => ({
    colorScheme,
    colors: {
        // override dark colors to change them for all components
        dark: [
            "#C1C2C5",
            "#A6A7AB",
            "#909296",
            "#5C5F66",
            "#373A40",
            "#2C2E33",
            "#1E1E1E",
            "#121212",
            "#141517",
            "#101113",
        ],
    },
});
